<template>


  <head>
    <meta charset="utf-8">
    <title class="">Trade License</title>
    <link href="https://fonts.googleapis.com/css?family=Aldrich|Fira+Sans:200,300,400,500,700,800,900|Norican&display=swap&subset=cyrillic" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,400,600,700&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Fondamento:ital@0;1&display=swap" rel="stylesheet">


  </head>

  <body>
  <page size="A4" id=""  class="p-2 container w-100 h-100 " style="position: relative;">

    <h1 style="text-transform: uppercase;font-size: 137px;position: absolute;transform: rotate(45deg);top: 63%;opacity: 10%;font-weight: 700;color: green; font-family: 'Pridi', serif; left: 13%;">{{permit.id}}-{{permit.businessID}}</h1>



    <section class="content-container h-100 w-100 position-relative p-2" style="max-height: 297mm !important">

      <header style="display: flex; height: 47mm; align-items: center; position: relative; width: 100%; justify-content: space-between; padding: 8mm;">
        <div>
          <img :src="county.stateLogo" style="height: 36mm; margin-right: 0mm;">
        </div>
        <div style="text-align: center; margin-right: 8.5mm; margin-left: 8.5mm;">
          <h2 style="
                        margin-top: 0px;
                        font-size: 26px;
                        text-transform: uppercase;
                        color: #00582f;
                        font-weight: 700;
                        margin-bottom: 1.2mm;
                        padding-bottom: 0px;

                    ">
            County Government Of {{county.countyName}}
          </h2>

          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 18px;
                    font-weight: bold; text-transform: uppercase; margin-bottom: 4.5mm;">
            {{permit.subCountyName}}
          </h4>

          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    font-weight:500;">
            {{county.address}} <br>
            Email: {{county.email}}
          </h4>

          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 500; font-style: italic; font-family: 'Times New Roman', Times, serif; margin-top: 2mm;">
            <span style="font-style: italic; font-family: 'Times New Roman', Times, serif; margin-right: 3mm;">{{county.tagline2}}</span>
            <span style="font-style: italic; font-family: 'Times New Roman', Times, serif;">{{county.tagline}}</span>
          </h4>

        </div>
        <div>
          <img :src="county.logo" style="height: 36mm; margin-right: 0mm;">
        </div>

      </header>

      <div class="cert-heading lora-heading my-3" style="padding-top: 0px !important; margin-top: 0.2in !important; text-align: center; display: flex; flex-flow: column; align-items: center; justify-content: center;">
        <h6 class=" " style=" margin-top: 0px; padding-top: 0px; font-size: 18px; font-weight: 400;font-family:'Pridi', serif !important;; margin-bottom: 0.2in; padding-bottom: 0px; text-transform: uppercase;">
          FOOD, DRUGS AND CHEMICAL SUBSTANCES ACT (Cap 254, Sub, Leg.)</h6>

        <h1 class="" style="font-family: 'Fondamento', cursive !important; font-size: 64px; margin: 0px; padding: 0px; width: 200mm; margin-top: 5mm; margin-bottom: 5mm; "><strong style=" font-family: 'Fondamento', cursive !important; ">Food Hygiene Licence</strong></h1>
        <h6 class=" " style=" margin-top: 0px; padding-top: 0px; font-size: 18px; font-weight: 400;font-family:'Pridi', serif !important;; margin-bottom: 0.2in; padding-bottom: 0px; text-transform: uppercase;">Form B</h6>
      </div>
      <section class="container p-3 m-0">
        <div class="row">

          <div class="col-12">
            <div style="display: flex; justify-content: center; align-items: center; flex-flow: column;">
              <p class=" col-12 p-0 spacing-1 line-height-2 mt-4 e " style="text-align: center; margin-bottom: 0.2in; font-size: 16px !important; font-weight: 600; " id="demo ">
                License is hereby granted to
                <span class="content-field" style="text-transform: uppercase; font-weight: 800;">{{permit.businessName}}</span>
                to sell, prepare,pack, store, display food at Plot No
                <span class="content-field" style="text-transform: uppercase; text-decoration: underline; font-weight: 800;"> {{permit.plotNumber}}</span>
                Situated at <strong class="content-field " style="text-transform: uppercase; font-weight: 800; text-decoration: underline;">{{permit.physicalAddress}}</strong>.
                Nature of Business
                <span class="content-field" style="text-transform: uppercase; text-decoration: underline; font-weight: 800;">{{permit.businessDes}}</span>
              </p>

              <p class=" col-12 p-0 spacing-1 line-height-2 mt-4 e " style="text-align: center; margin-bottom: 0.2in; font-size: 16px !important; font-weight: 600; " id="demo ">
                This licence expires on <span class="content-field" style="text-transform: uppercase; text-decoration: underline; font-weight: 800;">{{formattedDate(permit.endDate)}}</span>
                <br>
                This Licence is not transferable to any other premises.
              </p>


              <img src="./assets/ornament.svg" style="width: 170px; margin-bottom: 0.2in; margin-top: 0.2in;" />
            </div>
          </div>
          <div class="col-12 mt-4">
            <table class="table table-bordered">
              <tbody>


              <tr style="font-size: 14px;">
                <td colspan="2">
                  <span class="green">Nature Of Business<br></span>
                  <div style="display: flex; flex-flow: column;">
                    <span class="libre-bold">{{permit.businessDes}}</span>
                  </div>
                </td>
                <td colspan="2">
                  <span class="green">Expiry Date <br></span>
                  <span class="libre-bold">{{formattedDate(permit.endDate)}}</span>
                </td>

                <td colspan="2">
                  <span class="green">Invoiced By<br></span>
                  <span class="libre-bold">{{permit.createdBy}}</span>
                </td>

                <td colspan="2">
                  <img class="mb-2 float-left theqr " :src="link">
                </td>
              </tr>

              </tbody>
            </table>
          </div>




        </div>
      </section>

<!--      <img  src="assets/nbk_logo.svg" style="display: none;" alNBK Logo class="nbk" style="bottom: auto;">-->


    </section>

    <div style="position: absolute; bottom: 10mm; padding-right: 5mm; padding-left: 2mm;">
      <div style="display: flex; justify-content: center; align-items: flex-end; text-align: center; margin-bottom: 5mm;">
        <div>
          <p>{{permit.dateOfIssue}}</p>
          <hr style="width: 65mm; margin-bottom: 2mm; margin-top: 2mm;">
          <p>Date Approved</p>
        </div>
        <div style="margin-left: 5mm; margin-right: 5mm;">
          <div>
            <img :src="county.sealHealth" style=" height: 200px;" >
          </div>
        </div>
        <div>
          <div>
            <img :src="county.signatureHealth" alt=" The signature " class="the-stamp " style="height: 14mm;">
          </div>
          <hr style="width: 65mm; margin-bottom: 2mm; margin-top: 2mm;">
          <p>By Order Of <span class="text-uppercase" style="text-transform: uppercase;"><!--George Okelo Okelo--></span> Director Fire</p>
        </div>
      </div>
      <div>
        <div style="display: flex; justify-content: space-between; align-items:center; font-size: 12px; font-weight: 500;">
          <span>Permit No.: <span style="font-weight: bold; color: red;">{{permit.id}}-{{permit.businessID}}</span></span>
          <span>Date Downloaded {{currentDate()}}</span>
        </div>
        <img src="assets/green-line.png" style="width: 100%;">
      </div>
      <div class="col-12" style="font-size: 12px; padding: 0px; margin: 0px;">
        <h4 style="text-transform: uppercase;">County Government of {{county.countyName}}</h4>
        <p class="red font-14 " style="font-weight: 500; font-size: 12px !important;">
          Notice: Any alterations done after inspection that downgrade compliance will render the premises/Vehicle non-compliant and shall hold the owner of the building/premises liable for prosecution.
        </p>
      </div>
    </div>

  </page>
  <button class="print-btn " onclick="window.print() "><img src="assets/printer.svg " alt="Printer Icon "></button>


  </body>


</template>

<script>
/* eslint-disable */

import {execute, base, fire, health, biller} from "@/api";
import numWords from 'num-words';
import moment from "moment";
export default {
  name: "FirePermit",
  data() {
    return {
      bgLink: base+'/logo/bg-image-health.jpg',
      permit: {
        id: null,
        billNo: null,
        businessID: null,
        businessName: null ,
        duration: null,
        billTotal: null,
        receiptAmount: null,
        billBalance: null,
        startDate: null,
        endDate: null,
        statusID: null,
        status: null,
        printable: null,
        issuingOfficer: null,
        designation: null,
        dateOfIssue: null,
        stageStatus: null,
        comments: null,
        incomeTypeId: null,
        feeID: null,
        subCountyID: null,
        subCountyName: null,
        wardID: null,
        wardName: null,
        plotNumber: null,
        physicalAddress: null,
        buildingName: null,
        buildingOccupancy: null,
        floorNo: null,
        roomNo: null,
        premiseSize: null,
        numberOfEmployees: null,
        tonnage: null,
        businessDes: null,
        businessCategory: null,
        businessSubCategory: null,
        incomeTypeID: null,
        businessEmail:null,
        postalAddress: null,
        postalCode: null,
        businessPhone: null,
        contactPersonNames: null,
        contactPersonIDNo: null,
        businessRole: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        fullNames: null,
        ownerID: null,
        ownerPhone: null,
        ownerEmail: null,
        kraPin: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        lat: null,
        lng: null,
        lastRenewed: null,
        permitsCount: null,
        brimsCode: null,
        description:null,
      },
      link:'',
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        sealFire: null,
        signatureFire: null,
        signatureHealth: null,
        QRLink:null
      },
      trimmedSentences: []
    };
  },
  computed: {
    year() {
      let date = new Date(this.permit.startDate);
      let year = date.getFullYear();
      return year;
    }},
  mounted() {

    this.menuAccess();

    // Get the hash from the URL
    const hash = window.location.hash;

    const numberFromURL = hash.split('/')[3];


    // If number is passed in the URL
    if (numberFromURL) {
      console.log('Number from URL:', numberFromURL);
      this.printPermit(numberFromURL);
    }
    else {
      // Number is not passed in the URL, check localStorage
      let params = {};
      try {
        params = JSON.parse(localStorage.getItem('params'));
        console.log('Number from localStorage:', params.businessID);
        this.printPermit(params.businessID);
      } catch (error) {
        console.error('Error parsing localStorage:', error);
      }
    }

    //6832
    //this.printFirePermit("6832");


  },
  methods: {
    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Health Permit");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },
    currentDate(){
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let suffix = hour >= 12 ? "PM" : "AM";
      hour = hour % 12 || 12;
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + (month + 1) : month + 1;
      minute = minute < 10 ? "0" + minute : minute;
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let monthName = months[month - 1];
      let formattedDate = `${day} ${monthName} ${year} ${hour}:${minute} ${suffix}`;
      return formattedDate;
    },
    formattedDate(date2) {
      let date = new Date(date2);
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let monthName = months[month];
      return `${day} ${monthName} ${year}`;
    },

    numberWords(num){
      return numWords(num)
    },

    showDate() {
      const dateObj = new Date();
      const currentDate =
          dateObj.getDate() +
          "/" +
          dateObj.getMonth() +
          "/" +
          dateObj.getFullYear();
      return currentDate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    printPermit(businessID) {
      const data = new FormData();
      data.append("function", "printPermit");
      data.append("businessID", businessID);

      execute(data,health)
          .then((res) => {
            if (res.data.success) {
              this.permit = res.data.data.permit;
              this.county = res.data.data.county


              this.trimmedSentences = this.permit.description
                  .trim()
                  .slice(1, -1) // Remove square brackets
                  .split(",") // Split by commas
                  .map(sentence => sentence.trim())
                  .filter(sentence => sentence !== "" && sentence !== null && !sentence.includes("Recommendations"));


              this.link = this.county.QRLink+"Business ID: "+this.permit.businessID+" |\n "
                  +this.permit.businessName+" | start date: "+ this.permit.startDate+" | Expiry:"+this.permit.endDate;
            } else {
              alert(res.data.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    formattedTime(time) {
      return moment(time).format('hh:mm A')
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    },

    mailTradePermit(){
      const data = new FormData();
      data.append("function", "mailTradePermit");
      data.append("businessID", this.$route.params['data']);

      execute(data,fire)
          .then((res) => {
            alert(res.data.message);
          })
          .catch((e) => {
            alert(e.message);
          });

    }

  },
}

</script>


<style scoped>
canvas{
  display: none;
}

.print-btn {
  display: flex;
  z-index: 1000000000;
  position: fixed;
  background: #215939;
  color: white;
  top: 30px;
  right: 30px;
  border-radius: 50%;
  padding: 1rem;
  margin: 0px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 20px 4px rgb(136 136 136 / 65%);
  cursor: pointer;
  transition: 0.4s;
}

.print-btn img {
  height: 35px;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

@charset "utf-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.w-100 {
  width: 100%!important;
}

* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.title-header {
  display: flex;
  text-transform: capitalize;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.red {
  color: red !important;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

hr {
  border-top: 1px solid black !important;
}

.validations-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 120px;
}

img {
  vertical-align: middle;
  border-style: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  font-size: 14px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) .container {
                            max-width: 960px;
                          }

.text-right {
  text-align: right!important;
}

.p-0 {
  padding: 0!important;
}

.text-right {
  text-align: right!important;
}

.float-right {
  float: right!important;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

p {
  margin-bottom: 7.5px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.mb-5 {}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}

.mt-2,
.my-2 {
  margin-top: .5rem!important;
}

.logo {
  height: 150px;
}

.mx-2,
.mr-2 {
  margin-right: .5rem!important;
}

.mb-0,
.my-0 {
  margin-bottom: 0!important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.col-10 {
  flex: 0 0 75%;
  max-width: 75% !important;
}

.col-2 {
  width: 25% !important;
  flex: 0 0 25%;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.id-container {
  padding-left: 0px;
  border: 1px solid black;
  background: transparent;
}

.p-3 {
  padding: 1rem!important;
}

.m-0 {
  margin: 0!important;
}

.text-left {
  text-align: left!important;
}

.p-2 {
  padding: .5rem!important;
}

.h-100 {
  height: 100%!important;
}

.align-content-center {
  -ms-flex-line-pack: center!important;
  align-content: center!important;
}

.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}

.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.flex-column {
  -ms-flex-direction: column!important;
  flex-direction: column!important;
}

.table-bordered {
  border: 1px solid black;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

table {
  border-collapse: collapse;
}

.table td {
  padding: 7.5px;
}

.table {
  width: 100%;
  margin-bottom: 0px;
  color: #212529;
}

@media (min-width: 768px) .container {
                            max-width: 720px;
                          }

table {
  border-collapse: collapse;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid black;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
  border: 1px solid black;
}

.table td {
  padding: 7.5px;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem!important;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.h3,
h3 {
  font-size: 1.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 29px;
  font-weight: bold;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.position-absolute {
  position: absolute!important;
}

.position-relative {
  position: relative !important;
}

.d-none {
  display: none!important;
}

@media (min-width: 768px) .container {
                            max-width: 720px;
                          }

.font-14 {
  font-size: 14px !important;
}
/*	my styles*/
/* CSS Document */

body {
  font-size: 14px;
}

html,
body {
  width: 250mm;
  height: 353mm;
}

.content-container {
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}

.serial {
  /*
font-family: 'Aldrich', sans-serif !important;
font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", "Arial Black", "sans-serif";
*/
}

.title-font {
  /*
font-family: "Flamante SemiSlab Medium", "Flamante SemiSlab Bold" !important;
font-weight: bold;
font-family: "night train 315" !important;
font-family: ClementFivecleme !important;
*/
}

.validations-container tr td:first-child {
  color: #00582f;
  font-family: 'Open Sans', sans-serif;
}

.green {
  color: #00582f;
  font-family: 'Open Sans', sans-serif;
}

body {
  background: rgb(204, 204, 204);
}

page {
  display: block;
  margin: 0 auto;
  /* margin-bottom: 0.5cm; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  /*    background: repeating-linear-gradient( 45deg, #039840, #039840 10px, #067332 10px, #067332 20px );*/
  background: url("assets/bg-image.jpg");
  background-position: bottom;
  background-size: cover;
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

page[size="B4"] {
  width: 250mm;
  height: 353mm;
}

page[size="A4"][layout="portrait"] {
  width: 29.7cm;
  height: 21cm;
}

.theqr {
  width: 81px;
}

.libre-bold {
  font-family: 'Pridi', serif;
  font-weight: 400;
}

.libre-reg {
  font-family: 'Libre Baskerville', serif;
  font-weight: 300;
}

.a4-size {
  width: 595px;
  height: 842px;
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
}

.qr-container {
  width: 105px;
  height: 105px;
  border: 1px solid black;
}

.new-qr-container {
  border: 1px solid black;
  padding-bottom: 21px !important;
}

p {
  margin-bottom: 7.5px;
}

h4 {
  font-size: 15px;
  font-weight: bold;
}

header h3 {
  font-size: 24px;
  /*    font-weight: bold;*/
  text-transform: uppercase;
  white-space: nowrap;
}

header h5 {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Libre Baskerville', serif;
  text-transform: capitalize;
  white-space: nowrap;
}

.duration {
  padding: 7.5px;
  border: 1px solid black;
}

.table td {
  padding: 7.5px;
}

.issue-date {
  border: 1px solid black;
  border-right: 0px;
}

ol {
  list-style-type: lower-alpha;
}

.green {
  /*
display: block;
height: 8px;
color: green;
width: 100%;
background: #00421B;
border-bottom: 2px solid #FFDE00;
margin-bottom: 5px;
*/
}

.note {
  font-size: 12px;
}

.stamp-qr .the-stamp {
  width: 174px;
  transform: rotate(28deg);
  opacity: 100%;
  left: 0px;
  margin-left: -8px;
}

.nbk {
  float: right;
  height: 52px;
  position: absolute;
  right: 15px;
  bottom: 20px;
}

.r4-rem {
  margin-right: 4rem;
}

.exep {
  color: #dc3545;
}

.danger-line {
  background: repeating-linear-gradient( 45deg, #ff0101, #ff0101 10px, #dc3545 10px, #dc3545 20px);
  width: 100%;
  height: 3px;
  display: block;
}

.libre-it {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  font-size: 14px;
  text-transform: capitalize;
}

.locence-no,
.activity-code-bg {
  background: transparent !important;
  border: 1px solid black;
  border-left: none;
}

.footer-container {
  position: absolute;
  bottom: 15px;
  font-size: 8px;
  position: absolute;
  bottom: 15px;
  font-size: 8px;
  padding-top: 15px !important;
}

.the-seal {
  width: 119px;
  transform: rotate(15deg);
  position: absolute;
  z-index: 2000;
  top: -125px;
  right: -306px;
}

.neg2t {
  margin-top: -2%;
}

.serial-init {
  /*    font-family: "Flamante SemiSlab Medium", "Flamante SemiSlab Bold" !important;*/
}

.serial {
  font-family: Roman !important;
  font-family: ClementFivecleme !important;
  font-weight: 400;
}

.font-22 {
  font-size: 22px;
}

.stamp-qr {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: baseline;
}

.activity-code-bg {
  /*		background: #ff01014a !important;*/
}

.the-seal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
}

.font-12 {
  font-size: 12px !important;
}

@media print {
  @page {
    size: A4;

  }

  body,
  page {
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
    /* font-size: 18px; */
  }

  .captured {
    display: none;
  }

  canvas {
    display: block !important;
  }

  .print-btn {
    display: none !important
  }

  .stamp {
    opacity: 0.6 !important;
  }

  .content-container {
    width: 100%;
    width: 250mm;
    height: 353mm;
    height: 353mm;
  }

  html,
  body {
    width: 250mm !important;
    height: 353mm !important;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    padding: 0px !important;
  }

  page[size="B4"] {
    width: 250mm !important;
    height: 353mm !important;
    /*    padding: 30mm 30mm 30mm 30mm;*/
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100% !important;
    margin: 0px !important;
    /*
        height: 100vh !important;
        width: 100vw !important;
    */
  }

  @page {
    /* size: auto; */
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    /* width: 210mm;*/
    /* height: 297mm; */
    size: A4 portrait;
    margin: 0px;
    margin-bottom: 0px;
    margin: 0px;
  }

  ol {
    list-style-type: lower-alpha;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid black !important;
  }

  .table-striped tbody tr {
    background: transparent !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }

  .title-font {
    /* font-size: 20px; */
  }

  .font-22 {
    /* font-size: 24px !important; */
  }

  .opacity-0 {
    opacity: 0 !important;
  }

  .stamp {
    position: fixed;
    bottom: 82px;
    left: 246px;
    height: 188px;
    transform: rotate(45deg);
    opacity: 0;
  }

  @page {
    /*
width: 250mm;
height: 353mm;
*/
    /*   margin: 30mm 30mm 30mm 30mm;*/
  }

  /*	my styles*/
  @page {
    /* size: auto; */
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    /* width: 210mm;*/
    /* height: 297mm; */
    size: A4 portrait;
    margin: 0px;
    margin-bottom: 0px;
    margin: 0px;
  }
}
</style>






