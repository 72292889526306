<template>


<head>

    <meta charset="utf-8" />
    <title>Biller | {{logsName}}'s Details</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body @contextmenu.prevent data-sidebar="dark">



    <!-- Begin page -->
    <div id="layout-wrapper">


        <NavigationBar/>



        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">All User logs</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a @click="gotTo('dashboard')" href="javascript: void(0)">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a @click="gotTo('users')" href="javascript: void(0)">All users</a></li>
                                        <li class="breadcrumb-item"><a  href="javascript: void(0)">{{user.names}}</a></li>
                                        <li class="breadcrumb-item active">User logs</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                     <!-- user details -->
                     <div  v-if="logs.length<1 & loading === false" class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-3">
                                                        <div class="avatar-md mr-15px ">
                                                            <h1 class="avatar-title rounded-circle ">
                                                                CO
                                                            </h1>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 align-self-center">
                                                        <div class="text-muted">
                                                            <h5 class="mb-1">{{ user.names }}</h5>
                                                            <p class="mb-0">{{user.category}} (ID {{user.idNo}})</p>
                                                            <p class="text-muted mb-0 d-flex align-items-center">
                                                                <a href="tel:0704549859"
                                                                    class="d-flex align-items-center"><i
                                                                        class="mdi mdi-phone me-2 font-size-18"></i> {{user.phoneNumber}}</a> <span class="px-3 px-3">|</span>
                                                                <a class="d-flex align-items-center"
                                                                    href="mailto:email@email.com"><i
                                                                        class="mdi mdi-email-outline font-size-18 me-2"></i>
                                                                {{user.email}}</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 align-self-center">
                                                <div class="text-lg-center mt-4 mt-lg-0">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <div>
                                                                <p class="text-muted text-truncate mb-2">Sub County</p>
                                                                <h6 class="mb-0">{{user.subCountyName}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div>
                                                                <p class="text-muted text-truncate mb-2">Ward</p>
                                                                <h6 class="mb-0">{{user.wardName}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div>
                                                                <p class="text-muted text-truncate mb-2">Last Seen</p>
                                                                <h6 class="mb-0">{{user.lastSeen}}</h6>

                                                            </div>
                                                        </div>

                                                        <div class="col-3">
                                                            <div>
                                                                <p class="text-muted text-truncate mb-2">Current Zone</p>
                                                                <h6 class="mb-0">{{user.zone}}</h6>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-2 d-lg-block">
                                                <div class="clearfix mt-4 mt-lg-0">
                                                    <div class="dropdown float-end">
                                                        <div class="d-flex flex-column me-3 pe-3">
                                                            <span class="pr-3 fw-semibold mb-2">Added By</span>
                                                            <a> {{user.createdBy}} On {{user.dateCreated}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end row -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end of user details -->

                    <!-- tool bar -->



                    <!-- end of tool bar -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">

                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="bx  bx-trash label-icon"></i> Delete Contact(s)</button>
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-plus label-icon"></i> Compose Message</button>
                                    </div>
                                </div>


                              <div class="card-header bg-white w-100 border-bottom d-flex">

                                <div class="mr-15px">

                                  <button @click="downloadAllLogs()"  data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                    <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                  </button>

<!--                                  <select class="form-select form-control bg-white mr-15px" title="Select System User Type" data-live-search="true" data-style="" v-model="selectedItem">
                                    <option selected value="All users (100)">All users ({{users.length}})</option>
                                    <option  v-for="(item, index) in categories" :value="item.category" :key="index">{{item.category}}(<b>{{item.count}}</b>)</option>
                                  </select>-->

                                </div>

                                <div class="flex-grow-1">
                                  <div class="search-box mb-0 me-2 flex-grow-1">
                                    <div class="position-relative">
                                      <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search User">
                                      <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                  </div>
                                </div>


                              </div>



                                <div class="card-body">





                                    <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table" id="datatable-buttons">
                                        <thead class="table-light">
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Details</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in logs" :value="item" :key="index">
                                                <td><span class="fw-semibold">{{getHumanDate(item.date)}}</span></td>
                                                <td>{{item.description}}</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                        <div class="p-5 py-0 pt-3">
                                            <div class="p-5">
                                                <div class="spinner-border text-info m-1" role="status">
                                                    <span class="sr-only text-uppercase">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 class="text-uppercase">Loading Data</h4>
                                        <div class="col-sm-12 col-md-8 col-lg-5">
                                            <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                        </div>
                                    </div>

                                    <div v-if="logs.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                        <div class="p-5 py-0 pt-3">
                                            <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                        </div>
                                        <h4>No Results To Show</h4>
                                        <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                    </div>

                                    <div class="card-footer bg-white">
                                        <nav aria-label="Page navigation example "
                                             class="d-flex align-items-center justify-content-between">

                                            <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                                <select class="form-select w-auto font-13px " v-model="pageSize">
                                                    <option value="10">10 Rows</option>
                                                    <option value="25">25 Rows</option>
                                                    <option value="50">50 Rows</option>
                                                    <option value="100">100 Rows</option>
                                                    <option value="150">150 Rows</option>
                                                    <option value="200">200 Rows</option>
                                                    <option value="500">500 Rows</option>
                                                    <option value="1000">1000 Rows</option>
                                                    <option value="5000">5000 Rows</option>
                                                    <option value="10000">10000 Rows</option>
                                                </select>
                                            </div>

                                            <ul class="pagination">
                                                <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                    <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                                                </li>
                                                <!-- replace the v-for directive with the pagination function call -->
                                                <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                                    <!-- check if the page is an ellipsis and display it as plain text -->
                                                    <span v-if="page === '...'" class="page-link">{{ page }}</span>
                                                    <!-- otherwise, display it as a clickable link -->
                                                    <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                                                </li>
                                                <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                    <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                                                </li>
                                            </ul>


                                        </nav>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->



                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->






            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                           <!-- <script>
                                document.write(new Date().getFullYear())
                            </script> -->© Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import router from "@/router";
    import {authUrl, biller, execute, executeDownload} from "@/api";
    export default {
        name: "AddUser",
        components: {NavigationBar},
        data() {
            return{
               download:'Download',
                 search:'',
                loading:true,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                user: {
                    id: null,
                    names: null,
                    gender: null,
                    email: null,
                    phoneNumber: null,
                    idNo: null,
                    password: null,
                    category: null,
                    dateCreated:null,
                    zone: null,
                    subCountyID:null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    lat: null,
                    lng: null,
                    address: null,
                    lastSeen: null,
                    status: null,
                    des:null,
                    target: null,
                    permission: null,
                    dailyTargets: null,
                    monthlyTargets: null,
                    financialYearTargets:null,
                    createdBy: null
                },
                logsName:'',
                message:null,
                logs:[]
            }
        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },

        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getLogs("")//todo change
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            selectedItem: {
                handler() {
                    this.getLogs(this.selectedItem);
                }
            },
            search: {
                handler() {

                    this.getLogs("");
                }
            }
        },
        mounted() {
            this.getLogs()
            this.menuAccess()

        },
        methods:{

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "All User Logs");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getLogs("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getLogs("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getLogs("")
                }
            },
            gotTo(route){
                router.push(route)
            },

          getHumanDate(dateData) {
      const dateObject = new Date(Date.parse(dateData));

      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };

      return dateObject.toLocaleString('en-US', options);
    },

           downloadAllLogs(){
            this.download ="Please wait.."
            const data = new FormData();
            data.append("function", "downloadAllLogs");
             data.append("search", this.search);
             data.append("page", this.currentPage);
             data.append("rows_per_page", this.pageSize);

            executeDownload(data,authUrl).then(response => {
              this.download ="Download"
              const link = document.createElement('a');
              link.href = URL.createObjectURL(response.data);
              link.download = "AuditLogs.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
          },
            getLogs(){
                this.loading = true
                const data = new FormData();
                data.append("function", "getAllLogs");
                data.append("search", this.search);
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);

                execute(data,authUrl)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {

                            this.logs = res.data.data.logs
                            this.summary = res.data.data.resultSummary
                            this.totalPages =  Math.ceil(this.summary.count / this.pageSize);

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            }
        },
    }
</script>

<style scoped>

</style>